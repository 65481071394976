export const sideBarItems = {
  primary: [
    {
      label: 'Patient Details',
      icon: 'fluent:contact-card-32-regular',
    },
  ],
  secondary: [
    // {
    //   label: 'Document',
    //   icon: 'system-uicons:document-stack',
    // },
    {
      label: 'Logs',
      icon: 'solar:clipboard-list-line-duotone',
    },
    {
      label: 'Chat',
      icon: 'solar:chat-line-line-duotone',
    },
    // {
    //   label: 'Vitals',
    //   icon: 'solar:chat-line-line-duotone',
    // },
    {
      label: 'Video Call',
      icon: 'hugeicons:video-01',
    },
  ],
}

export const necesseryDocsList = ['consentForm']
